@import '../../node_modules/bulma/bulma.sass';
@import './config/_antd';
@import './config/_settings';
@import './config/mixins';
@import './components/titles';
@import './components/form';
@import './components/buttons';
@import './components/user-menu';
@import './components/header';
@import './components/footer';
@import './components/sidebar';
@import './components/sidebar-menu';
@import './components/lessons-list';
@import './components/course-sidebar';
@import './components/edit-student';
@import './pages/login';
@import './pages/course-list';
@import './pages/course';
@import './pages/lecture';
@import './pages/lecture-result';
@import './pages/lecture-review';
@import './pages/new-checkout';

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Manrope:wght@200..800&display=swap');

/* Add your own styles here. Or import above */
html {
  overflow-x: visible !important;
  overflow-y: visible !important;
}
body {
  font-family: 'Montserrat';
}

.hide {
  display: none !important;
}

.ant-layout {
  background-color: $main-color;
}
.main-layout-padding{
  padding: $main-layout-padding;
}
.main-layout {
  min-height: 100vh;
}
.page {
  .ant-layout-content {
    padding: $layout-space;
  }
  @include sm-max {
    &.login {
      padding-top: 0;
    }
    padding-top: 74px;
.ant-layout-content {
  padding-top: 70px;
}
  }
}
.text {
  color: $white-color;
  font-size: 1.5vw;
}

.ant-picker-dropdown,
.ant-select-dropdown,
.ant-notification {
  z-index: 9999999999;
}

.ant-popover {
  z-index: 9999999999 !important;
}


/*Modal fixed video bug*/
.lectureContainer {
  z-index: 0;
}
.ant-modal-mask {
  z-index: 0;
}
/*End modal*/


/*button acesso*/

.button-acesso-container {
  margin-left: 8%;
}

.button-acesso {
  color: white;
  background: #007AFF;
  border-radius: 8px;
  height: 68px;
  text-align: center;
}

.button-acesso-span {
  color: #fff; 
  font-Size: 16px !important;
  font-Weight: initial !important;
  margin-Top: 17px;
}

.supportedBrowsersModal .ant-modal-content {
  padding: 20px 0 30px 0;
  text-align: center;
}

.supportedBrowsersModal .ant-modal-content h1 {
  font-weight: 500 !important;
}

@media only screen and (max-width: 385px) {
  .button-acesso {
   font-size: 10px;
  }
}
@media only screen and (min-width: 540px) {
  .button-acesso {
   font-size: 18px;
  }
}
@media only screen and (max-width: 460px) {
  .button-acesso-container{
    margin: 2% !important;
  }
  .button-acesso-span {
    font-size: 100% !important;
  }
}
@media only screen and (min-width: 278px) and (max-width: 316px) {
  .ant-modal-body {
    margin: -7%!important;
  }
}

@media only screen and (min-width: 280px) and (max-width: 340px) {
  .page .ant-layout-content {
    padding: 20px 4px !important;
  }
}
/*/button acesso*/
.video-classRow {
  display: flex !important;
  gap: 40px !important;
}

@media only screen and (max-width: 992px) {
  .video-classRow{
    display: contents;
    justify-content: space-evenly !important;
   padding: 0px 11px 0px 0px;
  }
}


//MENTORSHIP
//ONGOING MENTORSHIP
.row-modal{
  padding: 30px;
  height: 670px;
  overflow: auto;
}

.row-modal-height {
  padding: 30px;
  height: 670px;
}

.modal-title-mentoria {
  font-size: 26px;
  font-weight: bold;
}

.modal-subTitle-mentoria {
  font-size: 16px;
  font-weight: 500;
}

.font-bold {
  font-Weight: bold;
}

.button-cancelar-mentoria {
  color: white;
  background-color: #ff4d4f;
}

.row-box {
  border: 1px #e6dcdcb8;
  border-style: solid;
  padding: 20px;
}

.botao-voltar {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 20px;
}

.d-flex-jc-gp {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}

//MENTORSIP

.col-solicitar-mentoria {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  gap: 15px;
}
.m-bottom-20 {
  margin-bottom: 20px;
}

.button-title-mentorship {
  margin-bottom: 20px;
  font-size: 26px;
  font-weight: bold;
}
.col-message {
  margin-bottom: 20px;
  font-size: 18px;
}
.p-message {
  display: contents;
  font-weight: 500; 
  font-size: 20px; 
}

.f-weight-500 {
  font-weight: 500;
}

.button-done {
  display: flex;
  width: 100%; 
  justify-content: flex-end; 
  align-items: flex-end;
}

.width-100 {
  width: 100%;
}

//MENTORSIP REALIZED
.title-mentoship-realized {
  font-weight:600;
  margin-top: 15px;
}

.row-box-realized {
  border: 1px #e6dcdcb8;
  border-style: solid;
  padding: 15px 23px;
  height: 163px;
}

.col-header {
  height: 50px;
}

.ant-input, .ant-input-password, .ant-picker, .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #999999;
  border-radius: 3px;
  line-height: 31px;
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 118px;
  cursor: pointer;
}

.upload-photo-avatar > .ant-upload {
  width: 86px;
  height: 86px;
  border-radius: 120px;
  cursor: pointer;

  > img {
    border-radius: 120px;
    border: 2px solid var(--Primrias-Lils, #5768ff);
  }

}
@media screen and (max-width: 768px) {
  .ant-upload-picture-card-wrapper{
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.upload-photo-perfil {
  border: 1px dashed black;
  width: 128px;
  height: 128px;
  padding: 35px;
  display: grid;
  background: #f5f5f5;
  cursor: pointer;
}

.button-delete-img {
  width: 130px;
  height: 29px;
  margin-bottom: 20px;
}

.modal-search-row{
  height: 25px;
  font-weight: bold;
  cursor: pointer;
}

.search-row {
  height: 25px; 
  overflow: hidden;
  cursor: pointer;
}

.courseDetail-loading {
  min-height: 720px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > span {
    margin-top: 5px;
    font-weight: bold;
  }
}

.take-off {
  background: none !important;
  border: none !important;
}

.take-off > div {
  background: none !important;
  border: none !important;
}

.take-off > div > div {
  background: none !important;
  border: none !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  display:none;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header{
  padding: 0;
}